import Home from "../../components/pages";

export const routes = [
  {
    name: "Home",
    path: `/`,
    id: "home",
    component: <Home />,
  },
];
